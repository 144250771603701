<template>
    <loader
        :isLoading="isLoading"
        :error="error !== false"
        :errorMessage="'membershipForm.error.' + error"
    >
        <h1 class="text-center mb-4">
            {{ $t("membershipForm.title", { year: campaign.year }) }}
        </h1>
        <v-stepper v-model="step">
            <v-stepper-header>
                <template v-for="i in 5">
                    <v-stepper-step
                        :key="'step-' + i"
                        :complete="step > i"
                        :step="i"
                        :editable="i < step && i < 4 && step < 5"
                    >
                        {{ $t("membershipForm.step." + i + ".header") }}
                    </v-stepper-step>

                    <v-divider v-if="i !== 5" :key="'divider-' + i"></v-divider>
                </template>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <association-form
                        v-model="associationForm"
                        :associationTypes="associationTypes"
                        :federations="federations"
                        @nextStep="onNextStep"
                    ></association-form>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <old-members-form
                        v-model="oldMembers"
                        @nextStep="onNextMembers"
                        @backStep="onBackStep"
                    ></old-members-form>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <members-form
                        v-if="step == 3"
                        v-model="members"
                        :instruments="instruments"
                        :positions="positions"
                        @nextStep="onNextStep"
                        @backStep="onBackStep"
                    ></members-form>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <summary-form
                        v-if="step == 4"
                        :associationId="associationId"
                        :association="associationForm"
                        :members="members"
                        :campaign="campaign"
                        :isPrimo="isPrimo"
                        :paymentMethods="paymentMethods"
                        @nextStep="onNextValidate"
                        @backStep="onBackStep"
                    ></summary-form>
                </v-stepper-content>
                <v-stepper-content step="5">
                    <h2 class="text-center">
                        {{ $t("membershipForm.step.5.title") }}
                    </h2>
                    {{ $t("membershipForm.step.5.text") }}
                    {{ $t("membershipForm.step.5.text2") }}
                    <p v-if="paymentMethod">
                        {{
                            $t(
                                "membershipForm.step.5.paymentMethod." +
                                    paymentMethod,
                                { price: price }
                            )
                        }}

                        <v-btn
                            v-if="paymentMethod == 'Virement'"
                            class="mt-2"
                            :href="getRibUrl()"
                            download
                        >
                            {{$t(
                                "membershipForm.step.5.rib"
                            )}}
                        </v-btn>
                    </p>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </loader>
</template>

<script>
import AssociationForm from "@/components/membership/AssociationForm";
import OldMembersForm from "@/components/membership/OldMembersForm";
import MembersForm from "@/components/membership/MembersForm";
import SummaryForm from "@/components/membership/SummaryForm";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        AssociationForm,
        MembersForm,
        OldMembersForm,
        SummaryForm,
        Loader,
    },

    props: {
        campaignId: {
            type: [String, Number],
            required: true,
        },
        associationId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            error: false,

            step: 1,

            isPrimo: false,
            campaign: {},
            associationForm: {
                name: "",
                siret: null,
                phone: "",
                address: {
                    street: "",
                    post_code: "",
                    city: "",
                },
                type: [],
                federation: null,
            },
            associationTypes: [],

            oldMembers: [],
            members: [],
            instruments: [],
            positions: [],
            federations: [],
            paymentMethods: [],

            paymentMethod: null,
            price: 0,
        };
    },

    mounted() {
        this.getAssociation();
    },

    methods: {
        getAssociation() {
            this.isLoading = true;
            this.error = false;
            this.showError = false;

            this.$store
                .dispatch("ohme/getAssociationCampaign", {
                    associationId: this.associationId,
                    campaignId: this.campaignId,
                })
                .then((data) => {
                    if (data.data.error) {
                        this.error = data.data.error;
                    } else {
                        this.campaign = data.data.campaign;
                        this.federations = data.data.federations;
                        this.setAssociationForm(data.data.association);

                        this.oldMembers = data.data.members.filter((item) => {
                            return item.année >= this.campaign.year - 1;
                        });
                        this.oldMembers = this.oldMembers.map((item) => {
                            item.isRenew = true;
                            return item;
                        });
                        if (this.oldMembers.length == 0) {
                            this.oldMembers = data.data.members;
                        }

                        this.instruments = data.data.instruments;
                        this.associationTypes = data.data.associationTypes;
                        this.positions = data.data.positions;

                        this.paymentMethods = data.data.paymentMethods;

                        var previousYear = parseInt(this.campaign.year) - 1;
                        this.isPrimo =
                            data.data.association.tags == undefined ||
                            !data.data.association.tags.includes(
                                "Adhérent " + previousYear
                            );
                    }

                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 404
                    ) {
                        this.$router.push({ name: "404" });
                    } else {
                        this.error = "showHhttpError";
                        this.isLoading = false;
                    }
                });
        },

        setAssociationForm(association) {
            var address = {
                street: "",
                post_code: "",
                city: "",
            };
            if (association.address !== null) {
                address = {
                    street: association.address.street,
                    post_code: association.address.post_code,
                    city: association.address.city,
                };
            }

            var federation = this.federations.find(
                (item) =>
                    item.federation_ohme_id ==
                    association.identifiant_federation
            );
            var identifiantFederation = federation
                ? federation.federation_ohme_id
                : null;

            this.associationForm = {
                name: association.name,
                siret: association.siret,
                phone: association.phone,
                address: address,
                type: association.type,
                federation: federation,
                identifiant_federation: identifiantFederation,
            };
        },

        onNextMembers() {
            this.oldMembers.forEach((oldMember) => {
                if (oldMember.isRenew) {
                    if (
                        !this.members.find((item) => item.id === oldMember.id)
                    ) {
                        this.members.push(Object.assign({}, oldMember));
                    }
                } else {
                    this.members = this.members.filter(
                        (item) => item.id !== oldMember.id
                    );
                }
            });

            this.onNextStep();
        },

        onNextValidate(paymentMethod, price) {
            this.paymentMethod = paymentMethod;
            this.price = price;
            this.onNextStep();
        },

        onNextStep() {
            this.step = this.step + 1;
        },

        onBackStep() {
            this.step = this.step - 1;
        },

        getRibUrl() {
            return process.env.VUE_APP_URL + "rib.pdf";
        },
    },
};
</script>
