var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"max-width":"800","persistent":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onAdd.apply(null, arguments)}}},[_c('v-card-text',[_c('h1',{staticClass:"text-center my-2"},[_vm._v(" "+_vm._s(_vm.$t("membershipForm.addMember"))+" ")]),_c('v-card',{staticClass:"my-1",attrs:{"outlined":"","color":"primary"}},[_c('v-card-text',[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t( "membershipForm.addMemberDialog.memberInformation" ))+" ")]),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":_vm.$t('members.member.civility') + '*',"rules":_vm.requiredRule,"items":_vm.civilities},model:{value:(_vm.newMember.civility),callback:function ($$v) {_vm.$set(_vm.newMember, "civility", $$v)},expression:"newMember.civility"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('members.member.lastName') + '*',"rules":_vm.requiredRule},model:{value:(_vm.newMember.lastname),callback:function ($$v) {_vm.$set(_vm.newMember, "lastname", $$v)},expression:"newMember.lastname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('members.member.firstName') + '*',"rules":_vm.requiredRule},model:{value:(_vm.newMember.firstname),callback:function ($$v) {_vm.$set(_vm.newMember, "firstname", $$v)},expression:"newMember.firstname"}})],1)],1),_c('v-text-field',{attrs:{"label":_vm.$t('members.member.birthDate'),"type":"date"},model:{value:(_vm.newMember.birthdate),callback:function ($$v) {_vm.$set(_vm.newMember, "birthdate", $$v)},expression:"newMember.birthdate"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.getFieldName(
                                            _vm.$t('members.member.email'),
                                            this.requiredForBoardRule
                                        ),"error-messages":_vm.requiredForBoardRule(
                                            _vm.newMember.email,
                                            'email'
                                        )
                                            ? _vm.requiredForBoardRule(
                                                  _vm.newMember.email,
                                                  'email'
                                              )
                                            : null},model:{value:(_vm.newMember.email),callback:function ($$v) {_vm.$set(_vm.newMember, "email", $$v)},expression:"newMember.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.getFieldName(
                                            _vm.$t('members.member.phone'),
                                            this.requiredForBoardRule
                                        ),"error-messages":_vm.requiredForBoardRule(
                                            _vm.newMember.phone,
                                            'phone'
                                        )
                                            ? _vm.requiredForBoardRule(
                                                  _vm.newMember.phone,
                                                  'phone'
                                              )
                                            : null},model:{value:(_vm.newMember.phone),callback:function ($$v) {_vm.$set(_vm.newMember, "phone", $$v)},expression:"newMember.phone"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.getFieldName(
                                            _vm.$t(
                                                'members.member.address.street'
                                            ),
                                            this.requiredAddressRule
                                        ),"error-messages":_vm.requiredAddressRule(
                                            _vm.newMember.address.street
                                        )
                                            ? _vm.requiredAddressRule(
                                                  _vm.newMember.address.street
                                              )
                                            : null},model:{value:(_vm.newMember.address.street),callback:function ($$v) {_vm.$set(_vm.newMember.address, "street", $$v)},expression:"newMember.address.street"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                            'members.member.address.street_2'
                                        )},model:{value:(_vm.newMember.address.street_2),callback:function ($$v) {_vm.$set(_vm.newMember.address, "street_2", $$v)},expression:"newMember.address.street_2"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.getFieldName(
                                            _vm.$t(
                                                'members.member.address.postCode'
                                            ),
                                            this.requiredAddressRule
                                        ),"error-messages":_vm.requiredAddressRule(
                                            _vm.newMember.address.post_code
                                        )
                                            ? _vm.requiredAddressRule(
                                                  _vm.newMember.address
                                                      .post_code
                                              )
                                            : null},model:{value:(_vm.newMember.address.post_code),callback:function ($$v) {_vm.$set(_vm.newMember.address, "post_code", $$v)},expression:"newMember.address.post_code"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":_vm.getFieldName(
                                            _vm.$t(
                                                'members.member.address.city'
                                            ),
                                            this.requiredAddressRule
                                        ),"error-messages":_vm.requiredAddressRule(
                                            _vm.newMember.address.city
                                        )
                                            ? _vm.requiredAddressRule(
                                                  _vm.newMember.address.city
                                              )
                                            : null},model:{value:(_vm.newMember.address.city),callback:function ($$v) {_vm.$set(_vm.newMember.address, "city", $$v)},expression:"newMember.address.city"}})],1)],1)],1)],1),_c('v-card',{staticClass:"my-1",attrs:{"outlined":"","color":"primary"}},[_c('v-card-text',[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t( "membershipForm.addMemberDialog.associationInformation" ))+" ")]),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"chips":"","deletable-chips":"","multiple":"","hide-details":"","label":_vm.$t('members.member.positions'),"items":_vm.positionsWithBoard},model:{value:(_vm.newMember.positions),callback:function ($$v) {_vm.$set(_vm.newMember, "positions", $$v)},expression:"newMember.positions"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"chips":"","deletable-chips":"","multiple":"","label":_vm.$t('members.member.instruments'),"items":_vm.instruments},model:{value:(_vm.newMember.instrument_principal),callback:function ($$v) {_vm.$set(_vm.newMember, "instrument_principal", $$v)},expression:"newMember.instrument_principal"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t(
                                            'members.member.annualHistory.instrumental'
                                        ) + '*',"rules":_vm.requiredBooleanSelectRule,"items":[
                                        {
                                            text: _vm.$t('button.yes'),
                                            value: true,
                                        },
                                        {
                                            text: _vm.$t('button.no'),
                                            value: false,
                                        } ]},model:{value:(_vm.newMember.cours_instrumental),callback:function ($$v) {_vm.$set(_vm.newMember, "cours_instrumental", $$v)},expression:"newMember.cours_instrumental"}}),_c('v-select',{attrs:{"label":_vm.$t(
                                            'members.member.annualHistory.musical'
                                        ) + '*',"rules":_vm.requiredBooleanSelectRule,"items":[
                                        {
                                            text: _vm.$t('button.yes'),
                                            value: true,
                                        },
                                        {
                                            text: _vm.$t('button.no'),
                                            value: false,
                                        } ]},model:{value:(
                                        _vm.newMember.cours_formation_musicale
                                    ),callback:function ($$v) {_vm.$set(_vm.newMember, "cours_formation_musicale", $$v)},expression:"\n                                        newMember.cours_formation_musicale\n                                    "}}),_c('v-select',{attrs:{"label":_vm.$t(
                                            'members.member.annualHistory.orchestre'
                                        ) + '*',"rules":_vm.requiredBooleanSelectRule,"items":[
                                        {
                                            text: _vm.$t('button.yes'),
                                            value: true,
                                        },
                                        {
                                            text: _vm.$t('button.no'),
                                            value: false,
                                        } ]},model:{value:(_vm.newMember.orchestre),callback:function ($$v) {_vm.$set(_vm.newMember, "orchestre", $$v)},expression:"newMember.orchestre"}}),_c('v-select',{attrs:{"label":_vm.$t(
                                            'members.member.annualHistory.formationExte'
                                        ) + '*',"rules":_vm.requiredBooleanSelectRule,"items":[
                                        {
                                            text: _vm.$t('button.yes'),
                                            value: true,
                                        },
                                        {
                                            text: _vm.$t('button.no'),
                                            value: false,
                                        } ]},model:{value:(
                                        _vm.newMember.formation_exterieure_en_ecole_de_musique
                                    ),callback:function ($$v) {_vm.$set(_vm.newMember, "formation_exterieure_en_ecole_de_musique", $$v)},expression:"\n                                        newMember.formation_exterieure_en_ecole_de_musique\n                                    "}})],1)],1)],1)],1),_c('v-card',{staticClass:"my-1",attrs:{"outlined":"","color":"primary"}},[_c('v-card-text',[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t("membershipForm.addMemberDialog.bfMag"))+" ")]),_c('v-text-field',{attrs:{"label":_vm.$t('members.member.bfMagNumber') + '*',"rules":_vm.requiredBFMagRule,"type":"number"},model:{value:(_vm.newMember.bfMag),callback:function ($$v) {_vm.$set(_vm.newMember, "bfMag", $$v)},expression:"newMember.bfMag"}})],1)],1),(_vm.formIsInvalid)?_c('p',{staticClass:"text-center warning--text"},[_vm._v(" "+_vm._s(_vm.$t("form.error"))+" ")]):_vm._e()],1),_c('v-card-actions',[(
                        this.member !== undefined &&
                            this.member.id === undefined
                    )?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.onDelete}},[_vm._v(" "+_vm._s(_vm.$t("button.delete"))+" ")]):_vm._e(),_c('v-spacer'),(this.member !== undefined)?_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("button.update"))+" ")]):_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")]),_c('v-btn',{on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("button.return"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }