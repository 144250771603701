<template>
    <v-form ref="form" @submit.prevent="onSubmit">
        <h2 class="text-center">
            <v-row>
                <v-col>
                    {{ $t("membershipForm.step.4.title") }}
                </v-col>
            </v-row>
        </h2>

        <v-row class="mt-4 mb-2" align="center">
            <v-col>
                <v-card
                    max-width="600"
                    class="mx-auto"
                    outlined
                    color="primary"
                >
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                {{ $t("membershipForm.summary.nbMembers") }} :
                            </v-list-item-content>

                            <v-list-item-action>
                                {{ members.length }}
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                {{ $t("membershipForm.summary.nbBFMag") }} :
                            </v-list-item-content>

                            <v-list-item-action>
                                {{ getNbBFMag() }}
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                {{
                                    $t("membershipForm.summary.firstMembership")
                                }}
                                :
                            </v-list-item-content>

                            <v-list-item-action>
                                {{
                                    isPrimo ? $t("button.yes") : $t("button.no")
                                }}
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-4 mb-2" align="center">
            <v-col>
                <v-card
                    max-width="600"
                    class="mx-auto"
                    outlined
                    color="primary"
                >
                    <v-list>
                        <template v-for="(item, index) in feesList">
                            <v-list-item
                                v-if="!item.hide"
                                :key="index"
                                :class="item.itemClass"
                            >
                                <v-list-item-content :class="item.contentClass">
                                    {{ item.title }} :
                                    <span
                                        v-if="item.subtitle"
                                        class="v-list-item__subtitle"
                                    >
                                        {{ item.subtitle }}
                                    </span>
                                </v-list-item-content>
                                <v-list-item-action :class="item.contentClass">
                                    {{ item.value }} €
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <v-select
            v-model="paymentMethod"
            :label="$t('membershipForm.summary.paymentMethod') + '*'"
            :rules="requiredRule"
            :items="paymentMethods"
        ></v-select>

        <v-row class="mt-4 mb-2">
            <v-col class="text-center">
                <v-btn
                    :disabled="isLoading"
                    @click="$emit('backStep')"
                    class="mr-2"
                >
                    {{ $t("membershipForm.back") }}
                </v-btn>
                <v-btn :loading="isLoading" color="primary" type="submit">
                    {{ $t("membershipForm.validate") }} </v-btn
                ><br />
            </v-col>
        </v-row>
        <p v-if="formIsInvalid" class="text-center warning--text">
            {{ $t("form.error") }}
        </p>
        <p v-if="error !== false" class="text-center error--text">
            {{ $t("membershipForm.error." + error) }}
        </p>
    </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        associationId: {
            type: [String, Number],
            required: true,
        },

        association: {
            type: Object,
            required: true,
        },

        isPrimo: {
            type: Boolean,
            required: true,
        },

        members: {
            type: Array,
            required: true,
        },

        campaign: {
            type: Object,
            required: true,
        },

        paymentMethods: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            error: false,
            isLoading: false,

            formIsInvalid: false,

            paymentMethod: null,

            feesList: [
                {
                    title: this.$t("membershipForm.summary.associationFee"),
                    value: this.getAssociationFee(),
                },
                {
                    title: this.$t(
                        "membershipForm.summary.individualContributions.title"
                    ),
                    subtitle: this.$t(
                        "membershipForm.summary.individualContributions.text",
                        {
                            value: this.campaign.individual_contributions,
                        }
                    ),
                    value: this.getIndividualContributions(),
                },
                {
                    hide:
                        !this.campaign.exceptional_discount_value ||
                        this.campaign.exceptional_discount_value == 0,
                    title: this.$t(
                        "membershipForm.summary.exceptionalDiscount"
                    ),
                    subtitle: this.campaign.exceptional_discount_text,
                    value: this.getExceptionalDiscount(),
                },
                {
                    title: this.$t("membershipForm.summary.bfMag.title"),
                    subtitle: this.$t("membershipForm.summary.bfMag.text", {
                        value: this.getBfMagPrice(),
                    }),
                    value: this.getBfMag(),
                },
                {
                    title: this.$t("membershipForm.summary.nationalShare"),
                    value: this.getNationalShare(),
                    itemClass: "info mt-1",
                    contentClass: "white--text",
                },
                {
                    title: this.$t(
                        "membershipForm.summary.associationFederalFee"
                    ),
                    value: this.getAssociationFederalFee(),
                },
                {
                    title: this.$t(
                        "membershipForm.summary.individualFederalFee.title"
                    ),
                    subtitle: this.$t(
                        "membershipForm.summary.individualFederalFee.text",
                        {
                            value: this.getIndividualFederal(),
                        }
                    ),
                    value: this.getIndividualFederalFee(),
                },
                {
                    title: this.$t("membershipForm.summary.federalShare"),
                    value: this.getFederalShare(),
                    itemClass: "info mt-1",
                    contentClass: "white--text",
                },
                {
                    title: this.$t("membershipForm.summary.total"),
                    value: this.getTotal(),
                    itemClass: "primary mt-1 mb-n2",
                    contentClass: "white--text",
                },
            ],
        };
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
        }),
    },

    methods: {
        getNbBFMag() {
            const reducer = (accumulator, member) =>
                accumulator + parseInt(member.bfMag);

            return this.members.reduce(reducer, 0);
        },

        getAssociationFee() {
            if (this.isPrimo) {
                return parseFloat(this.campaign.association_fee_primo);
            } else {
                return parseFloat(this.campaign.association_fee_renew);
            }
        },

        getIndividualContributions() {
            if (this.isPrimo) {
                return 0;
            } else {
                return (
                    parseFloat(this.campaign.individual_contributions) *
                    this.members.length
                );
            }
        },

        getExceptionalDiscount() {
            return -(
                Math.round(
                    (this.campaign.exceptional_discount_value / 100) *
                        (this.getAssociationFee() +
                            this.getIndividualContributions()) *
                        100
                ) / 100
            );
        },

        getBfMag() {
            return this.getBfMagPrice() * this.getNbBFMag();
        },

        getBfMagPrice() {
            var price = this.campaign.bf_mag[0].price;
            var index = 1;

            while (
                index < this.campaign.bf_mag.length &&
                parseInt(this.campaign.bf_mag[index].quantity) <=
                    this.getNbBFMag()
            ) {
                price = this.campaign.bf_mag[index].price;
                index++;
            }

            return parseFloat(price);
        },

        getNationalShareWithoutBfMag() {
            return (
                this.getAssociationFee() +
                this.getIndividualContributions() +
                this.getExceptionalDiscount()
            );
        },

        getNationalShare() {
            return this.getNationalShareWithoutBfMag() + this.getBfMag();
        },

        getIndividualFederal() {
            if (this.association.federation) {
                return parseFloat(this.association.federation.member);
            } else {
                return 0;
            }
        },

        getAssociationFederalFee() {
            if (this.association.federation) {
                return parseFloat(this.association.federation.fixed);
            } else {
                return 0;
            }
        },

        getIndividualFederalFee() {
            if (this.association.federation) {
                return (
                    parseFloat(this.association.federation.member) *
                    this.members.length
                );
            } else {
                return 0;
            }
        },

        getFederalShare() {
            return (
                this.getAssociationFederalFee() + this.getIndividualFederalFee()
            );
        },

        getTotal() {
            return this.getNationalShare() + this.getFederalShare();
        },

        onSubmit() {
            if (this.$refs.form.validate()) {
                this.error = false;
                this.isLoading = true;

                if (this.association.federation) {
                    this.association.identifiant_federation = this.association.federation.federation_ohme_id;
                } else {
                    this.association.identifiant_federation = null;
                }

                var form = {
                    association: this.association,
                    members: this.members,
                    amount: {
                        part_federale: this.getFederalShare(),
                        montant_cotisation_confederation: this.getNationalShareWithoutBfMag(),
                        part_bf_mag: this.getBfMag(),
                        total: this.getTotal(),
                    },
                    paymentMethod: this.paymentMethod,
                };

                this.$store
                    .dispatch("ohme/setAssociationCampaign", {
                        associationId: this.associationId,
                        campaignId: this.campaign.id,
                        form: form,
                    })
                    .then((data) => {
                        this.isLoading = false;
                        if (data.data.error) {
                            this.error = data.data.error;
                        } else {
                            this.$emit(
                                "nextStep",
                                this.paymentMethod,
                                this.getTotal()
                            );
                        }
                    })
                    .catch((err) => {
                        if (
                            err !== undefined &&
                            err.response !== undefined &&
                            err.response.status == 403
                        ) {
                            this.$router.push({ name: "403" });
                        } else if (
                            err !== undefined &&
                            err.response !== undefined &&
                            err.response.status == 404
                        ) {
                            this.$router.push({ name: "404" });
                        } else {
                            this.error = "saveHttpError";
                            this.isLoading = false;
                        }
                    });
            } else {
                this.formIsInvalid = true;
            }
        },
    },
};
</script>
