<template>
    <div>
        <h2 class="text-center">
            <v-row>
                <v-col>
                    {{ $t("membershipForm.step.2.title") }}
                </v-col>
            </v-row>
        </h2>
        <v-row>
            <v-col>
                {{ $t("membershipForm.step.2.text") }}<br />
                {{ $t("membershipForm.step.2.text2") }}
                <v-list>
                    <v-list-item>
                        <v-list-item-action>
                            <v-checkbox
                                class="primary--text --primary"
                                @click="selectAll"
                                v-model="allMembresSelected"
                                color="grey"
                                hide-details
                            >
                            </v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="grey--text">
                                {{ $t("membershipForm.step.2.selectAll") }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="member in members" :key="member.id">
                        <template>
                            <v-list-item-action>
                                <v-checkbox
                                    v-model="member.isRenew"
                                    v-on:change="selectMember()"
                                ></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ member.lastname }} {{ member.firstname }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row class="mt-4 mb-2">
            <v-col class="text-center">
                <v-btn @click="$emit('backStep')" class="mr-2">
                    {{ $t("membershipForm.back") }}
                </v-btn>
                <v-btn color="primary" @click="onSubmit">
                    {{ $t("membershipForm.next") }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            members: this.value,
            allMembresSelected: true,
        };
    },

     mounted() {
        this.members = this.members.map(item => {item.isRenew = true; return item;})
    },

    watch: {
        members: function () {
            this.$emit("data", this.members);
        },
    },

    methods: {
        onSubmit() {
            this.members = this.members.map((item) => {
                item.bfMag = 0;
                item.cours_instrumental = false;
                item.cours_formation_musicale = false;
                item.orchestre = false;
                item.formation_exterieure_en_ecole_de_musique = false;
                if (item.address == null) {
                    item.address = {
                        street: null,
                        street_2: null,
                        post_code: null,
                        city: null,
                    };
                }
                return item;
            });
            this.$emit("nextStep");
        },

        selectMember: function () {
            this.$forceUpdate();
            this.allMembresSelected = this.members.every((item) => {
                return item.isRenew;
            });
        },

        selectAll: function () {
            var state = this.allMembresSelected;

            this.members.forEach((item) => {
                item.isRenew = state;
            });

            this.$forceUpdate();
        },
    },
};
</script>
