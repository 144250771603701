<template>
    <v-dialog :value="true" max-width="800" persistent>
        <v-card>
            <v-form ref="form" @submit.prevent="onAdd">
                <v-card-text>
                    <h1 class="text-center my-2">
                        {{ $t("membershipForm.addMember") }}
                    </h1>
                    <v-card outlined color="primary" class="my-1">
                        <v-card-text>
                            <h3 class="primary--text">
                                {{
                                    $t(
                                        "membershipForm.addMemberDialog.memberInformation"
                                    )
                                }}
                            </h3>
                            <v-row class="mt-1">
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="newMember.civility"
                                        :label="
                                            $t('members.member.civility') + '*'
                                        "
                                        :rules="requiredRule"
                                        :items="civilities"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="newMember.lastname"
                                        :label="
                                            $t('members.member.lastName') + '*'
                                        "
                                        :rules="requiredRule"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="newMember.firstname"
                                        :label="
                                            $t('members.member.firstName') + '*'
                                        "
                                        :rules="requiredRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field
                                v-model="newMember.birthdate"
                                :label="$t('members.member.birthDate')"
                                type="date"
                            ></v-text-field>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="newMember.email"
                                        :label="
                                            getFieldName(
                                                $t('members.member.email'),
                                                this.requiredForBoardRule
                                            )
                                        "
                                        :error-messages="
                                            requiredForBoardRule(
                                                newMember.email,
                                                'email'
                                            )
                                                ? requiredForBoardRule(
                                                      newMember.email,
                                                      'email'
                                                  )
                                                : null
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="newMember.phone"
                                        :label="
                                            getFieldName(
                                                $t('members.member.phone'),
                                                this.requiredForBoardRule
                                            )
                                        "
                                        :error-messages="
                                            requiredForBoardRule(
                                                newMember.phone,
                                                'phone'
                                            )
                                                ? requiredForBoardRule(
                                                      newMember.phone,
                                                      'phone'
                                                  )
                                                : null
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="newMember.address.street"
                                        :label="
                                            getFieldName(
                                                $t(
                                                    'members.member.address.street'
                                                ),
                                                this.requiredAddressRule
                                            )
                                        "
                                        :error-messages="
                                            requiredAddressRule(
                                                newMember.address.street
                                            )
                                                ? requiredAddressRule(
                                                      newMember.address.street
                                                  )
                                                : null
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        v-model="newMember.address.street_2"
                                        :label="
                                            $t(
                                                'members.member.address.street_2'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" class="py-0">
                                    <v-text-field
                                        v-model="newMember.address.post_code"
                                        :label="
                                            getFieldName(
                                                $t(
                                                    'members.member.address.postCode'
                                                ),
                                                this.requiredAddressRule
                                            )
                                        "
                                        :error-messages="
                                            requiredAddressRule(
                                                newMember.address.post_code
                                            )
                                                ? requiredAddressRule(
                                                      newMember.address
                                                          .post_code
                                                  )
                                                : null
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="8" class="py-0">
                                    <v-text-field
                                        v-model="newMember.address.city"
                                        :label="
                                            getFieldName(
                                                $t(
                                                    'members.member.address.city'
                                                ),
                                                this.requiredAddressRule
                                            )
                                        "
                                        :error-messages="
                                            requiredAddressRule(
                                                newMember.address.city
                                            )
                                                ? requiredAddressRule(
                                                      newMember.address.city
                                                  )
                                                : null
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card outlined color="primary" class="my-1">
                        <v-card-text>
                            <h3 class="primary--text">
                                {{
                                    $t(
                                        "membershipForm.addMemberDialog.associationInformation"
                                    )
                                }}
                            </h3>
                            <v-row class="mt-1">
                                <v-col class="pb-0" cols="12">
                                    <v-autocomplete
                                        chips
                                        deletable-chips
                                        multiple
                                        hide-details
                                        v-model="newMember.positions"
                                        :label="$t('members.member.positions')"
                                        :items="positionsWithBoard"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="pb-0" cols="12">
                                    <v-autocomplete
                                        chips
                                        deletable-chips
                                        multiple
                                        v-model="newMember.instrument_principal"
                                        :label="
                                            $t('members.member.instruments')
                                        "
                                        :items="instruments"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="pb-0" cols="12">
                                    <v-select
                                        v-model="newMember.cours_instrumental"
                                        :label="
                                            $t(
                                                'members.member.annualHistory.instrumental'
                                            ) + '*'
                                        "
                                        :rules="requiredBooleanSelectRule"
                                        :items="[
                                            {
                                                text: $t('button.yes'),
                                                value: true,
                                            },
                                            {
                                                text: $t('button.no'),
                                                value: false,
                                            },
                                        ]"
                                    >
                                    </v-select>

                                    <v-select
                                        v-model="
                                            newMember.cours_formation_musicale
                                        "
                                        :label="
                                            $t(
                                                'members.member.annualHistory.musical'
                                            ) + '*'
                                        "
                                        :rules="requiredBooleanSelectRule"
                                        :items="[
                                            {
                                                text: $t('button.yes'),
                                                value: true,
                                            },
                                            {
                                                text: $t('button.no'),
                                                value: false,
                                            },
                                        ]"
                                    >
                                    </v-select>

                                    <v-select
                                        v-model="newMember.orchestre"
                                        :label="
                                            $t(
                                                'members.member.annualHistory.orchestre'
                                            ) + '*'
                                        "
                                        :rules="requiredBooleanSelectRule"
                                        :items="[
                                            {
                                                text: $t('button.yes'),
                                                value: true,
                                            },
                                            {
                                                text: $t('button.no'),
                                                value: false,
                                            },
                                        ]"
                                    >
                                    </v-select>

                                    <v-select
                                        v-model="
                                            newMember.formation_exterieure_en_ecole_de_musique
                                        "
                                        :label="
                                            $t(
                                                'members.member.annualHistory.formationExte'
                                            ) + '*'
                                        "
                                        :rules="requiredBooleanSelectRule"
                                        :items="[
                                            {
                                                text: $t('button.yes'),
                                                value: true,
                                            },
                                            {
                                                text: $t('button.no'),
                                                value: false,
                                            },
                                        ]"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card outlined color="primary" class="my-1">
                        <v-card-text>
                            <h3 class="primary--text">
                                {{ $t("membershipForm.addMemberDialog.bfMag") }}
                            </h3>

                            <v-text-field
                                v-model="newMember.bfMag"
                                :label="$t('members.member.bfMagNumber') + '*'"
                                :rules="requiredBFMagRule"
                                type="number"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                    <p v-if="formIsInvalid" class="text-center warning--text">
                        {{ $t("form.error") }}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        v-if="
                            this.member !== undefined &&
                                this.member.id === undefined
                        "
                        color="error"
                        @click="onDelete"
                    >
                        {{ $t("button.delete") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="this.member !== undefined"
                        color="primary"
                        type="submit"
                    >
                        {{ $t("button.update") }}
                    </v-btn>
                    <v-btn v-else color="primary" type="submit">
                        {{ $t("button.add") }}
                    </v-btn>
                    <v-btn @click="onClose">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        member: {
            type: Object,
        },
        instruments: {
            type: Array,
            required: true,
        },
        positions: {
            type: Array,
            required: true,
        },
        board: {
            type: Object,
            required: true,
        },
        memberIsBoard: {
            type: Function,
            required: true,
        },
         addId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            formIsInvalid: false,

            newMember: this.member,

            positionsWithBoard: [],
            civilities: [
                { text: "M.", value: "Monsieur" },
                { text: "Mme", value: "Madame" },
            ],

            requiredBFMagRule: [
                (value) => value >= 0 || this.$t("form.required"),
            ],
        };
    },

    created() {
        if (this.newMember == undefined) {
            this.newMember = {
                firstname: null,
                lastname: null,
                email: null,
                phone: null,
                address: {
                    street: null,
                    street_2: null,
                    post_code: null,
                    city: null,
                },
                birthdate: null,
                bfMag: 0,
                positions: [],
                instrument_principal: null,
                cours_instrumental: false,
                cours_formation_musicale: false,
                orchestre: false,
                formation_exterieure_en_ecole_de_musique: false,
                addId: this.addId,
            };
        }
        if (this.newMember.address == null) {
            this.newMember.address = {
                street: null,
                street_2: null,
                post_code: null,
                city: null,
            };
        }
        this.setPositions();
    },

    mounted() {
        if (this.member !== undefined) {
            this.$refs.form.validate();
        }
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            emailRule: (state) => state.utils.emailRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
            requiredBooleanSelectRule: (state) =>
                state.utils.requiredBooleanSelectRule,
        }),

        isBoard() {
            return this.memberIsBoard(this.newMember);
        },
    },

    methods: {
        getFieldName(fieldName, requiredFunction) {
            if (requiredFunction(null)) {
                return fieldName + "*";
            } else {
                return fieldName;
            }
        },

        requiredAddressRule(value) {
            if (this.newMember.bfMag > 0 && !value) {
                return this.$t("form.required");
            }
            if (this.isBoard && !value) {
                return this.$t("form.requiredForBoard");
            }
        },

        requiredForBoardRule(value, type = null) {
            if (this.isBoard && !value) {
                return this.$t("form.requiredForBoard");
            }
            if (value && type == "email") {
                const pattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,15}/;
                if (!pattern.test(value)) {
                    return this.$t("form.email.invalid");
                }
            }
            if (value && type == "phone") {
                const pattern = /^(([+][0-9][0-9])|0)([0-9]{9})$/;
                if (!pattern.test(value)) {
                    return this.$t("form.phone");
                }
            }
        },

        onAdd() {
            if (
                this.$refs.form.validate() &&
                this.requiredAddressRule(this.newMember.address.street) ==
                    null &&
                this.requiredAddressRule(this.newMember.address.post_code) ==
                    null &&
                this.requiredAddressRule(this.newMember.address.city) == null &&
                this.requiredForBoardRule(this.newMember.email, "email") ==
                    null &&
                this.requiredForBoardRule(this.newMember.phone, "phone") == null
            ) {
                if (this.member !== undefined) {
                    this.$emit("updateMember", this.newMember);
                } else {
                    this.$emit("addMember", this.newMember);
                }
            } else {
                this.formIsInvalid = true;
            }
        },

        onDelete() {
            this.$emit("deleteMember", this.member);
        },

        onClose() {
            this.$emit("close");
        },

        setPositions() {
            this.positionsWithBoard = [];
            this.positions.forEach((item) => {
                var disabled = false;

                if (
                    item == "Président(e)" &&
                    !this.newMember.positions.includes("Président(e)") &&
                    this.board.president > 0
                ) {
                    disabled = true;
                }
                if (
                    item == "Directeur(trice)" &&
                    !this.newMember.positions.includes("Directeur(trice)") &&
                    this.board.director > 0
                ) {
                    disabled = true;
                }
                if (
                    item == "Correspondant(e)" &&
                    !this.newMember.positions.includes("Correspondant(e)") &&
                    this.board.contact > 0
                ) {
                    disabled = true;
                }

                this.positionsWithBoard.push({
                    text: item,
                    value: item,
                    disabled: disabled,
                });
            });
        },
    },
};
</script>
