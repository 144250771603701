<template>
    <div>
        <h2 class="text-center">
            <v-row>
                <v-col>
                    {{ $t("membershipForm.step.3.title") }}
                </v-col>
                <v-col cols="auto" class="text-right">
                    <v-btn color="success" @click="onAddMember()" class="mr-2">
                        {{ $t("membershipForm.addMember") }}
                    </v-btn>
                </v-col>
            </v-row>
        </h2>
        <v-alert type="error" class="ma-4" outlined>
            {{ $t("membershipForm.step.3.warning") }}
        </v-alert>

        <v-data-table
            class="mt-4"
            :headers="headers"
            :items="members"
            disable-pagination
            hide-default-footer
            @click:row="onUpdateMember"
            mobile-breakpoint="0"
        >
            <template v-slot:[`item.birthdate`]="{ item }">
                {{ getDate(item.birthdate) }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon> mdi-account-edit </v-icon>
                <v-icon v-if="!memberIsValid(item)" color="error">
                    mdi-alert
                </v-icon>
            </template>
        </v-data-table>

        <v-row class="mt-4 mb-2">
            <v-col class="text-center">
                <v-btn @click="$emit('backStep')" class="mr-2">
                    {{ $t("membershipForm.back") }}
                </v-btn>
                <v-btn color="primary" @click="onSubmit">
                    {{ $t("membershipForm.next") }} </v-btn
                ><br />
                <p v-if="errorBoard" class="error--text">
                    {{ $t("membershipForm.errorBoard") }}
                </p>
            </v-col>
        </v-row>

        <member-dialog
            v-if="addDialog"
            :member="dialogMember !== null ? dialogMember : undefined"
            @addMember="addMember"
            @updateMember="updateMember"
            @deleteMember="deleteMember"
            @close="addDialog = false"
            :instruments="instruments"
            :positions="positions"
            :board="board"
            :memberIsBoard="memberIsBoard"
            :addId="addId"
        ></member-dialog>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import MemberDialog from "@/components/membership/MemberDialog";

export default {
    components: {
        MemberDialog,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        instruments: {
            type: Array,
            required: true,
        },

        positions: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            members: this.value,

            addDialog: false,
            dialogMember: null,
            dialogMemberIndex: null,
            addId: 0,

            newMemberForm: {},
            headers: [
                {
                    text: "",
                    value: "edit",
                    width: "80px",
                    class: "primary primary--text",
                },
                {
                    text: this.$t("members.member.lastName"),
                    value: "lastname",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.firstName"),
                    value: "firstname",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.birthDate"),
                    value: "birthdate",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.bfMag"),
                    value: "bfMag",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.instruments"),
                    value: "instrument_principal",
                    class: "primary white--text",
                },
                {
                    text: this.$t("members.member.positions"),
                    value: "positions",
                    class: "primary white--text",
                },
            ],
        };
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
        }),

        board() {
            const reducer = (accumulator, currentValue) => {
                if (currentValue.positions.includes("Président(e)")) {
                    accumulator.president++;
                }
                if (currentValue.positions.includes("Directeur(trice)")) {
                    accumulator.director++;
                }
                if (currentValue.positions.includes("Correspondant(e)")) {
                    accumulator.contact++;
                }
                return accumulator;
            };

            var value = this.members.reduce(reducer, {
                president: 0,
                director: 0,
                contact: 0,
            });

            return value;
        },

        errorBoard() {
            return !(
                this.board.president == 1 &&
                this.board.director == 1 &&
                this.board.contact == 1
            );
        },
    },

    watch: {
        members: function () {
            this.$emit("data", this.members);
        },
    },

    methods: {
        onAddMember() {
            this.addId = this.addId + 1;
            this.dialogMember = null;
            this.addDialog = true;
        },

        addMember(member) {
            this.members.push(member);
            this.addDialog = false;
        },

        onUpdateMember(member) {
            this.dialogMember = Object.assign({}, member);
            this.addDialog = true;
        },

        updateMember(member) {
            let index = this.members.findIndex(
                (item) => (item.id && item.id == member.id) || (item.addId && item.addId == member.addId)
            );
            if (index >= 0) {
                this.members.splice(index, 1);
                this.members.push(member);
            }

            this.addDialog = false;
        },

        deleteMember(member) {
            let index = this.members.findIndex(
                (item) => item.addId == member.addId
            );
            if (index >= 0) {
                this.members.splice(index, 1);
            }
            this.addDialog = false;
            this.$forceUpdate();
        },

        memberIsBoard(member) {
            return (
                member.positions &&
                (member.positions.includes("Président(e)") ||
                    member.positions.includes("Directeur(trice)") ||
                    member.positions.includes("Correspondant(e)"))
            );
        },

        memberIsValid(member) {
            return (
                member.civility &&
                member.lastname &&
                member.firstname &&
                (!this.memberIsBoard(member) ||
                    (member.email &&
                        member.phone &&
                        member.address &&
                        member.address.street &&
                        member.address.post_code &&
                        member.address.city)) &&
                (member.bfMag == 0 ||
                    (member.bfMag > 0 &&
                        member.address &&
                        member.address.street &&
                        member.address.post_code &&
                        member.address.city))
            );
        },

        onSubmit() {
            if (!this.errorBoard && this.members.every(this.memberIsValid)) {
                this.$emit("nextStep");
            }
        },

        getDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            }
        },
    },
};
</script>
