<template>
    <div>
        <h2 class="text-center">
            {{ $t("membershipForm.step.1.title") }}
        </h2>
        <v-form ref="form" @submit.prevent="onSubmit">
            <v-row class="mt-1">
                <v-col>
                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="associationForm.name"
                                :label="
                                    $t('associations.association.name') + '*'
                                "
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                type="number"
                                v-model="associationForm.siret"
                                :label="$t('associations.association.siret')"
                                :rules="siretRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="associationForm.address.street"
                                :label="
                                    $t(
                                        'associations.association.address.street'
                                    ) + '*'
                                "
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" class="py-0">
                            <v-text-field
                                v-model="associationForm.address.post_code"
                                :label="
                                    $t(
                                        'associations.association.address.postCode'
                                    ) + '*'
                                "
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                            <v-text-field
                                v-model="associationForm.address.city"
                                :label="
                                    $t(
                                        'associations.association.address.city'
                                    ) + '*'
                                "
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-text-field
                        v-model="associationForm.phone"
                        :label="$t('associations.association.phone')"
                        :rules="[phoneRule.phone(associationForm.phone)]"
                    ></v-text-field>

                    <v-autocomplete
                        chips
                        deletable-chips
                        multiple
                        :rules="requiredAutocompleteRule"
                        v-model="associationForm.type"
                        :label="$t('associations.association.type') + '*'"
                        :items="associationTypes"
                    ></v-autocomplete>

                    <v-autocomplete
                        clearable
                        v-model="associationForm.federation"
                        :label="$t('associations.association.federation')"
                        :items="federations"
                        item-text="name"
                        return-object
                        hide-details
                    ></v-autocomplete>
                    <span class="grey--text">{{
                        $t("membershipForm.unkownFederation")
                    }}</span>
                </v-col>
            </v-row>

            <v-row class="mb-2">
                <v-col class="text-center">
                    <v-btn color="primary" type="submit">
                        {{ $t("membershipForm.next") }}
                    </v-btn>
                    <p v-if="formIsInvalid" class="text-center warning--text">
                        {{ $t("form.error") }}
                    </p>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        associationTypes: {
            type: Array,
            required: true,
        },
        federations: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            formIsInvalid: false,

            associationForm: this.value,

            requiredAutocompleteRule: [
                (value) =>
                    (!!value && value.length > 0) || this.$t("form.required"),
            ],
        };
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            phoneRule: (state) => state.utils.phoneRule,
            siretRule: (state) => state.utils.siretRule,
        }),
    },

    watch: {
        associationForm: function() {
            this.$emit("data", this.associationForm);
        },
    },

    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.$emit("nextStep");
            } else {
                this.formIsInvalid = true;
            }
        },
    },
};
</script>
